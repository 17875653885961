import Column from "./column";

export default function EventDisplay(props) {
  const groupEventsByDay = props?.events.reduce((acc, event) => {
    const date = new Date(event.date);
    const key = date.toISOString().split("T")[0];

    if (!acc[key]) {
      acc[key] = [];
    }

    acc[key].push(event);
    return acc;
  }, {});

  return (
    <div class="flex flex-row min-w-full h-full rounded-md gap-x-2 <md:(!flex-col gap-y-2) ">
      {Object.keys(groupEventsByDay).map((dayKey) => {
        const eventsForDay = groupEventsByDay[dayKey];
        const firstEvent = eventsForDay[0];
        const firstEventDate = new Date(firstEvent.date);
        const monthName = firstEventDate.toLocaleString("default", { month: "short" }).toUpperCase();
        console.log("event :: ", monthName);
        return (
          <Column
            year={firstEventDate.getFullYear()}
            day={firstEventDate.getDate()}
            month={firstEventDate.getMonth() + 1}
            monthName={monthName}
            dayOfWeek={firstEventDate.toLocaleDateString("en-US", { weekday: "short" })}
            events={eventsForDay}
          />
        );
      })}
    </div>
  );
}
